import $ from 'jquery';
import 'slick-carousel';
import 'lazysizes';

require([
    "jquery"
], function ($) {

    $(document).ready(function () {

        $('.jCarousel').slick({
            autoplay: true,
            autoplaySpeed: 3000
        });
        $('.products-related .product-items').slick({
            autoplay: true
        });

        $('.jsMenuToggle').on('click', function() {
            toggleMobileNav();
        });

        var toggleMobileNav = function() {

            $('.jsMenuOpen').toggle();
            $('.jsMenuClose').toggle();
            $('.jsMenuToggleOpen').toggle();
            $('.jsMenuToggleClose').toggle();

            var htmlElement = document.querySelector("html");
            if (htmlElement.classList.contains("jsMenuIsOpen")) {
                htmlElement.classList.remove("jsMenuIsOpen");
                for (let el of document.querySelectorAll(".jsMobileIconsClosedNav")) {
                    el.style.opacity = "1";
                }
            } else {
                htmlElement.classList.add("jsMenuIsOpen");
                for (let el of document.querySelectorAll(".jsMobileIconsClosedNav")) {
                    el.style.opacity = "0";
                }
            }
        };

        $('.jsBrands').slick(
            {
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
        );

        $('.jsProductCarousel').slick(
            {
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                ]
            }
        );

        var navbar = $('.nav-sections');
        var fixMainNav = function() {
            var a = $(window).scrollTop();
            var b = navbar.height();

            if (a > b + b) {
                navbar.addClass("fixed-nav");
            } else {
                navbar.removeClass("fixed-nav");
            }
        };

        fixMainNav();
        $(window).scroll(function () {
            fixMainNav();
        });

    });
});